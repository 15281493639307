.custom-categories__block {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
}


.custom-categories__title {
    color: #102038;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}


.custom-categories {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minMax(200px, 1fr));
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    column-gap: 20px;
    row-gap: 20px;
}

.custom-categories__item {
    padding: 10px 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    position: relative;
    background: #FFF;
    box-shadow: 0px 6px 15px 0px rgba(239, 239, 239, 0.80);
    overflow: hidden;
}

.custom-categories__item:first-of-type {
    background: #ECC781;
}

.custom-categories__link-icon {
    width: 18px;
    height: 18px;
    margin: 0 10px 0 auto;
    z-index: 2;
    position: relative;
}


.custom-categories__img {
    aspect-ratio: 240 / 168;
    object-fit: cover;
    width: 100%;
    margin: 13px 0 0;
    z-index: 2;
    position: relative;
}

.custom-categories__item-title {
    color: #102038;

    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 122%;
    margin: 16px 0 0;
    z-index: 2;
    position: relative;
}

.custom-categories__item-count {
    color: #686B70;
    margin: 6px 0 0;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    z-index: 2;
    position: relative;
}


.custom-categories__dot {
    width: 12px;
    height: 12px;
    background-color: #ECC781;
    border-radius: 50%;
    margin: 11px 0 0;
    z-index: 2;
    position: relative;
}

.custom-categories__item:first-of-type .custom-categories__dot {
    background-color: #FFF;
}

.custom-categories__item:first-of-type .custom-categories__item-bg {
    display: none;
}

.custom-categories__item-bg {
    position: absolute;
}

.custom-categories__item-bg_1 {
    width: 392px;
    height: 392px;
    top: 24px;
}

.custom-categories__item-bg_2 {
    width: 268px;
    height: 268px;
    top: -76px;
    left: -62px;
}

.custom-categories__item-bg_3 {
    width: 342px;
    height: 342px;
    top: -166px;
}

.custom-categories__item-bg_4 {
    width: 342px;
    height: 342px;
    top: -128px;
    left: -205px;
}

.custom-categories__item-bg_5 {
    width: 392px;
    height: 392px;
    top: -160px;
    left: -245px;
}

.custom-categories__item-bg_6 {
    width: 392px;
    height: 392px;
    top: -223px;
    right: -186px;
}

.custom-categories__item-bg_7 {
    width: 268px;
    height: 268px;
    top: -84px;
    right: -80px;
}

.custom-categories__item-bg_8 {
    width: 342px;
    height: 342px;
    top: -133px;
    left: -194px;
}

.custom-categories__item-bg_9 {
    width: 342px;
    height: 342px;
    top: -202px;
}


@media (max-width: 700px) {}

@media (max-width: 580px) {
    .custom-categories {
        grid-template-columns: 1fr 1fr;
    }
    .custom-categories__item-title{
        font-size: 14px;
    }
}