.сustom-banner {
    width: 100%;
    border-radius: 12px;
    background: #E7EAF3;
    padding: 10px 60px 10px 50px;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content;
    column-gap: 40px;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}

.сustom-banner__logo-box {
    position: relative;
    z-index: 2;
    width: 200px;
    height: 200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #E7EAF3;
}

.сustom-banner__logo-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.сustom-banner__logo {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: absolute;
    z-index: 2;
}

.сustom-banner__logo-fill {
    fill: #8D96AC;
}

.сustom-banner__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    position: relative;
    z-index: 2;
}

.сustom-banner__texts {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.сustom-banner__title {
    color: #2C314F;

    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    letter-spacing: 1.92px;
    text-transform: uppercase;
}

.сustom-banner__text {
    color: #6F7387;

    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 118%;
}

.сustom-banner__button {
    color: #102038;

    font-size: 20px;
    font-style: normal;
    font-weight: 550;
    line-height: 130%;
    border-radius: 8px;
    background: #ECC781;
    padding: 16px 24px;
    box-sizing: border-box;
    white-space: nowrap;
}

.сustom-banner__bg-elem {
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    box-sizing: border-box;
}

.сustom-banner__bg-elem_1 {
    background-color: #CED5EA;
    width: 50px;
    height: 50px;
    top: 23px;
    left: 799px;
}

.сustom-banner__bg-elem_2 {
    width: 304px;
    height: 304px;
    border: 4px solid #CED5EA;
    top: -104px;
    left: 672px;
}

.сustom-banner__bg-elem_3 {
    width: 524px;
    height: 524px;
    border: 20px solid #CED5EA;
    top: 101px;
    left: 562px;
}

.сustom-banner__bg-elem_4 {
    width: 524px;
    height: 524px;
    border: 30px solid #CED5EA;
    top: -273px;
    left: 1045px;
}

@media (max-width: 900px) {
 .сustom-banner__logo-box{
    width: 140px;
    height: 140px;
 }
}

@media (max-width: 840px) {
 .сustom-banner__content{
    padding: 20px 0;
    flex-direction: column;
    align-items: flex-start;
 }
 .сustom-banner__button{
    width: 100%;
 }

.сustom-banner__bg-elem_1 {
    left: 539px;
}

.сustom-banner__bg-elem_2 {
    left: 412px;
}

.сustom-banner__bg-elem_3 {
    left: -262px;
}

.сustom-banner__bg-elem_4 {
    left: 745px;
}
}

@media (max-width: 750px) {
 .сustom-banner__title{
    font-size: 32px;
 }
 .сustom-banner__text{
    font-size: 18px;
 }
 .сustom-banner__button{
    font-size: 18px;
 }
}

@media (max-width: 580px) {
 .сustom-banner{
    display: flex;
    flex-direction: column;
    padding: 10px 40px;
 }
 .сustom-banner__logo-box{
    width: 200px;
    height: 200px;
 }
 .сustom-banner__content{
    align-items: center;
 }
 .сustom-banner__title{
    text-align: center;
 }
 .сustom-banner__text{
    text-align: center;
 }
 .сustom-banner__bg-elem_1 {
    left: 299px;
}

.сustom-banner__bg-elem_2 {
    left: 172px;
}

.сustom-banner__bg-elem_3 {
    left: -24px;
}

.сustom-banner__bg-elem_4 {
    left: 545px;
}
}