.product-card {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 12px;
    background: #FFF;

    box-shadow: 0px 6px 15px 0px rgba(239, 239, 239, 0.80);
    padding: 8px;
}

.product-card__title {
    padding: 0 8px;
    margin: 16px 0 0;
    box-sizing: border-box;
    display: block;
    color: #102038;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 112%;
    overflow-wrap: break-word;
    white-space: pre-line;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    /* Ensures that content does not exceed 2 lines, but does not guarantee a minimum */
}

.product-card__description {
    margin: 6px 0 0;
    padding: 0 8px;
    box-sizing: border-box;
    display: block;

    color: #6F7387;

    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 128%;
    /* Example line height; adjust based on your design */
    /* min-height: calc((var(14px)*1.28)*2); */
    /* For two lines of text, assuming line-height is 1.1em */
    overflow-wrap: break-word;
    white-space: pre-line;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* Ensures that content does not exceed 2 lines, but does not guarantee a minimum */
}

/* Update your existing styles for .product-card__title and .product-card__description to include min-height */
.product-card__title {
    width: 100%;
    /* Adjust based on your design */
    /* Ensure min-height is set to at least twice the line-height to accommodate two lines */
    min-height: calc((16px*1.12)*2);
    /* Adjust the multiplier based on your actual line-height */
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.product-card__description {
    width: 100%;
    padding-bottom: 16px;
    /* Adjust based on your design */
    /* Ensure min-height is set to at least twice the line-height to accommodate two lines */
    /* min-height: calc((var(--caption-size)*1.1)*2); */
    /* Adjust the multiplier based on your actual line-height */
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.product-card__image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 8px;
    /* height: 100%; */
    /* object-fit: cover; */
    /* background-color: var(--neutral-background); */
}

.product-card__image_format_10x16 {
    aspect-ratio: 10 / 16;
}

.product-card__image_format_16x10 {
    aspect-ratio: 16 / 10;
}

.product-card__image_format_1x1 {
    aspect-ratio: 1 / 1;
}


.product-card__button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 16px 8px 8px;
    box-sizing: border-box;
    white-space: nowrap;
}

.product-card__button_type_separately {}

.product-card__button_type_on_button {
    padding: 14px 16px;
    box-sizing: border-box;
    border-radius: calc(var(--initial_border_radius-value) * (1.50 * 1));
    background-color: var(--secondary-active);
    transition: all 0.2s ease-in-out;
}






.product-card__button-price {}

.product-card__button-price_discount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.product-card__button-price-discounted {
    text-decoration: line-through;


}

.product-card__price-discount {
    padding: 4px 8px;
    border-radius: calc(var(--initial_border_radius-value) * (1.50 * 2));
    background-color: var(--semantic_minus-active);
    color: var(--website_background-background);
    position: absolute;
    right: 0;
    transform: rotate(-6.726deg);

}

.product-card__button_type_on_button .product-card__button-price {
    color: var(--website_background-background);
}


.product-card__button_type_on_button .product-card__button-price-discounted {
    color: var(--website_background-background);
}


.product-card__button-to-cart {
    margin: auto 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 18px;
    box-sizing: border-box;
    gap: 10px;
    border-radius: 6px;
    background: #ECC781;
    transition: all 0.2s linear;
}

.product-card__button_in-cart .product-card__button-to-cart {
    background-color: #F3F4F7;
}

.product-card__button-to-cart-text {
    color: #102038;

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    transition: all 0.2s linear;
}

.product-card__button_type_on_button .product-card__button-to-cart-text {
    color: var(--website_background-background);
}

.product-card__button_in-cart .product-card__button-to-cart-text {
    color: #2C314F;
}


.product-card__button-to-cart-icon {
    width: var(--h3-size);
    height: var(--h3-size);
}

.product-card__button-to-cart-icon-fill {
    fill: var(--secondary-active);
}

.product-card__button_type_on_button .product-card__button-to-cart-icon-fill {
    fill: var(--website_background-background);
}


.product-card__last {
    margin: auto 0 0;
}

.product-card__separator {
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px;
    height: 1px;
    background-color: #EAEBEE;
    margin-top: auto;
}


@media (max-width: 1000px) {
    .product-card__title {
        font-size: calc(16px* 0.7);
        min-height: calc(((16px* 0.7)*1.12)*2);
    }

    .product-card__description {
        font-size: calc(var(--caption-size)* 0.7);
    }

    .product-card__button-price {
        font-size: calc(var(--text-size) * 0.7);
    }

    .product-card__button-to-cart-text {
        font-size: calc(var(--text-size) * 0.7);
    }

    .product-card__button-to-cart-icon {
        width: calc(var(--text-size) * 0.7);
        height: calc(var(--text-size) * 0.7);
    }
}

@media (max-width: 500px) {
    .product-card__button {
        flex-direction: column;
        align-items: flex-start;
    }

    .product-card__button-to-cart {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}