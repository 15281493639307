.about-banner {
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content;
    align-items: center;
    column-gap: 140px;
}

.about-banner__logo {
    width: 240px;
    height: 240px;
}

.about-banner__logo-fill {
    fill: #8D96AC;
}

.about-banner__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.about-banner__title {
    color: #102038;
    font-size: 62px;
    font-style: normal;
    font-weight: 800;
    line-height: 106%;
    letter-spacing: 3.72px;
}

.about-banner__text {
    color: #6F7387;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%s;
}

@media (max-width: 880px) {
    .about-banner {
        column-gap: 60px;
    }

    .about-banner__logo {
        width: 200px;
        height: 200px;
    }

    .about-banner__text {
        font-size: 18px;
    }
}

@media (max-width: 700px) {
    .about-banner {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .about-banner__content {
        max-width: 400px;
        align-items: center;
    }

    .about-banner__title {
        font-size: 44px;
        text-align: center;
    }

    .about-banner__text {
        text-align: center;
        font-size: 16px;
    }
}