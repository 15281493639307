.order {
    border-radius: 12px;
    border: 1px solid #ECC781;
    width: 100%;
    height: fit-content;
    max-width: 280px;
    position: sticky;
    display: flex;
    flex-direction: column;
    padding: 18px 12px;
    box-sizing: border-box;
    top: 64px;
}

.order__preloader{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 84px;
    margin: 16px 0 0;
}


.order__title {
    padding: 0 2px;
    box-sizing: border-box;
    color: var(--neutral-active);

}

.order__params {
    margin-top: 16px;
    padding: 0 4px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.order__param {
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: max-content;
    column-gap: 4px;
    align-items: end;
}

.order__param-line {
    opacity: 0.20000000298023224;
    background: var(--primary-active);
    height: 0.0625rem;
    width: 100%;
}

.order__param-name {

    color: var(--neutral-active);
}

.order__param-name_not-availible{
    text-align: center;
    color: var(--semantic_minus-active);
}


.order__param_type_total .order__param-name {
    color: var(--neutral-active);
}

.order__param-value {
    text-align: right;
    color: var(--neutral-active);
}



.order__param_type_total .order__param-value {
    color: var(--neutral-active);
}

.order__hint {
    width: 100%;
    margin-top: 150px;
    padding: 0 4px;
    box-sizing: border-box;
    text-align: center;
    color: var(--neutral-active);
}



.order__controls {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    margin-top: 12px;
}



.order__btn-submit {

    background: var(--primary-active);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: -0.375rem;
    text-align: center;

    border-radius: calc(var(--initial_border_radius-value) * (1.5* 1));
    padding: 10px 14px;

    transition: all 0.2s linear;
    min-height: 39px;
}

.order__btn-submit-text {
    color: var(--website_background-background);
}

.order__btn-submit_inactive {
    opacity: 0.3;
    pointer-events: none;
    cursor: no-drop;
}

.order__promo {
    border-bottom: 1px solid var(--primary-active);
    box-sizing: border-box;
    padding: 2px 2px 2px 12px;
    display: flex;
    height: 32px;
    /* margin-top: 0.88rem; */
    align-items: center;
    gap: 8px;
}

.order__promo-button {
    height: 100%;
    background: var(--primary-active);
    border-radius: calc(var(--initial_border_radius-value) * (1.5* 1));
    /* padding: 0.62rem; */
    padding: 0 10px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: right;
    transition: opacity 0.3s ease-in;
}

.order__promo-button-text {
    color: var(--website_background-background);
    font-size: var(--caption-size);
    font-weight: var(--caption-weight);
}

.order__promo-button_inactive {
    opacity: 0.3;
    pointer-events: none;
}

.order__minimal-order{
    color: var(--semantic_minus-active);
    font-size: var(--caption-size);
    font-weight: var(--caption-weight);
}

.order__not-deliverable{
    font-size: var(--caption-size);
    font-weight: var(--caption-weight);
    color: var(--semantic_minus-active); 
}

.order__delivery-time{
    font-size: var(--caption-size);
    font-weight: var(--caption-weight);
    color: var(--neutral-active); 
    opacity: 0.7;
}

@media (max-width: 1050px) {
    .order {
        position: relative;
        top: unset;
        max-width: 580px;
    }
}

@media (max-width: 700px) {
    .order {
        max-width: unset;
    }
}