.popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(98, 106, 134, 0.60);
    box-sizing: border-box;
    padding: 24px;
    visibility: hidden;
    opacity: 0;
    z-index: 10000;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.popup_opened {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.popup__container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: var(--website_background-background);
    border-radius: 12px;
    box-sizing: border-box;
    padding: 24px 12px;
    z-index: 1000;

}


@media (max-width: 650px) {

    .popup__container {
        padding: 12px;
    }

}