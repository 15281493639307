.catalog-drop__container {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: visibility 0.3s, opacity 0.3s linear;

}


.catalog-drop__container_active {
    visibility: visible;
}

.catalog-drop__content {
    background: var(--website_background-background);
    width: 100vw;
    max-width: 1280px;
    height: 100%;

    display: flex;
    flex-direction: row;
    width: 100%;

    box-sizing: border-box;


    z-index: 11;
    padding: 24px 40px;
    /* min-height: 200px; */
    /* box-shadow: 0px 0px 10px rgba(2, 2, 2, 0.08); */
    gap: 32px;
    /* border-radius: calc(var(--initial_border_radius-value) * (1.50 * 1)); */

}

.catalog-drop {
    background: var(--website_background-background);
    /* border-radius: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100vw;
    opacity: 0;
    position: absolute;
    top: 75px;
    height: calc(102vh);
}

.catalog-drop_active {
    opacity: 1;
    animation: fadein 0.5s;
    /*
    animation-iteration-count: 1; */
}

@keyframes fadein {
    0% {
        transform: translateY(-50px);
        opacity: 0
    }

    100% {
        transform: translateY(0);
        opacity: 1
    }
}


.catalog-drop__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--neutral-active);
    opacity: 0;
    z-index: 9;
    transition: opacity 0.4s linear;
}

.catalog-drop__background_active {
    opacity: 0.3;
    transition: opacity 0.4s linear;
}


.catalog-drop__categories {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 100%;
    overflow: auto;
    padding-right: 10px;
    box-sizing: border-box;
    width: 284px;
}



.catalog-drop__categories::-webkit-scrollbar {
    width: 3px;
}

.catalog-drop__categories::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px var(--main-element-secondary-bg); */
    border: none;
    outline: none;
    background-color: transparent;
}

.catalog-drop__categories::-webkit-scrollbar-thumb {
    background-color: var(--secondary-active);
    outline: none;
    border-radius: 100px;
    overflow: visible;
}

.item-comments__items_hide-scroll::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px var(--main-element-secondary-bg); */
    border: none;
    outline: none;
    background-color: transparent !important;
}



.catalog-drop__card {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 24px;
    grid-template-rows: max-content;
    column-gap: 20px;
    padding: 12px 10px 12px 20px;
    border-radius: calc(var(--initial_border_radius-value) * (1.50 * 2));
    box-sizing: border-box;
    background-color: transparent;
    transition: all 0.2s linear;
    cursor: pointer;
}



.catalog-drop__card:hover {
    background: var(--secondary-selection);
}

.catalog-drop__card_selected {
    background: var(--secondary-selection);
}

.catalog-drop__card_selected .catalog-drop__card-name {
    color: var(--neutral-active);
}

.catalog-drop__card_selected .catalog-drop__card-arrow-fill {
    fill: var(--neutral-active);
}

.catalog-drop__card-info {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    column-gap: 5px;
}

.catalog-drop__card-icon {
    width: 24px;
    height: 24px;
}

.catalog-drop__card-icon-fill {
    fill: var(--neutral-active);
}

.catalog-drop__card-icon-stroke {
    stroke: var(--neutral-active);
}

.catalog-drop__card-name {
    margin: 0;
    color: var(--neutral-active);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    transition: all 0.2s linear;
}

.catalog-drop__card-arrow {
    width: 20px;
    height: 20px;
}

.catalog-drop__card-arrow-fill {
    fill: var(--neutral-active);
}

.catalog-drop__preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 284px - 32px);
    height: 100%;
}

.catalog-drop__subcategories {
    width: calc(100% - 284px - 32px);
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: max-content;
    align-items: center;
    grid-auto-rows: max-content;
    column-gap: 20px;
    row-gap: 20px;
}

.catalog-drop__subcategory {
    padding: 4px 0;
    box-sizing: border-box;
    border-radius: 1000px;
    background-color: transparent;
    transition: all 0.2s linear;
    cursor: pointer;

    margin: 0;
    color: var(--neutral-active);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: fit-content;
    line-height: 130%;
}

.catalog-drop__subcategory:hover {
    color: var(--neutral-selection);
}


@media (max-width: 960px) {
    .catalog-drop__subcategories {
        grid-template-columns: repeat(2, minmax(0, 1fr));

    }
}


@media (max-width: 780px) {
    .catalog-drop {
        display: none !important;
    }
}