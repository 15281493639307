.benefits {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content max-content;
    column-gap: 16px;
    row-gap: 16px;
}

.benefits__item {
    padding: 60px 50px 60px 40px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 2px solid #ECC781;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content;
    column-gap: 12px;
}

.benefits__item-texts {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.benefits__item-title {
    color: #102038;

    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 107%;
}

.benefits__item-text {
    color: #6F7387;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 125%;
}

.benefits__item-icon{

}

.benefits__item-icon_1{
    height: 166px;  
    aspect-ratio: 174 / 166;  
}


.benefits__item-icon_2{
    height: 170px;  
    aspect-ratio: 134 / 170;
}

.benefits__item-icon_3{
    height: 160px;
    aspect-ratio: 1 / 1;
}

.benefits__item-icon_4{
    height: 170px;
    aspect-ratio: 153 / 170;
}

.benefits__item-icon-stroke{
    stroke: #ECC781;
}

@media (max-width: 980px) {
 .benefits__item{
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 24px;
 }
 .benefits__item-icon_1{
    height: 170px; 
}


.benefits__item-icon_2{
    height: 170px; 
}

.benefits__item-icon_3{
    height: 170px; 
}

.benefits__item-icon_4{
    height: 170px; 
}
}

@media (max-width: 630px) {
 .benefits{
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
 }
}