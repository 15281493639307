.footer {
  width: 100%;
  min-width: 225px;
  box-sizing: border-box;
  margin: auto 0 0;
  padding: 64px 120px;
  display: flex;
  flex-direction: column;
  align-items: center;

border-top: 1px solid #E3E5F2;
  margin-top: 100px;
  background-color: #262424;
}

.footer__box {
  width: 100%;
  max-width: 1392px;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: max-content;
  column-gap: 40px;
}

.footer__content {
  max-width: 1392px;
  width: 100%;
  display: flex;
  gap: 40px;
  /* grid-template-columns: 0.43fr 0.2fr 0.3fr;
  grid-template-rows: 1fr 1fr;*/
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.footer__content-box {
  max-width: 1392px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  /* grid-template-columns: 0.43fr 0.2fr 0.3fr;
  grid-template-rows: 1fr 1fr;*/
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.footer__content-for-buyer {
  display: flex;
  flex-direction: column;
}


.footer__content_contacts {
  display: flex;
  flex-direction: column;
}

.footer__content_link {
  color: #FFFFFF;
  line-height: 1.4375rem;
  letter-spacing: -0.04375rem;
}

.footer__content_title {
  color: var(--primary-active);
}

.footer__content-email-subscribe {}

.footer__email-block {
  border-radius: 30px;
  border: 1px solid var(--primary-active);
  box-sizing: border-box;
  padding: 2px 2px 2px 12px;
  display: flex;
  height: 2rem;
  margin-top: 0.88rem;
  align-items: center;
  gap: 8px;
}

.footer__email-block_button {
  height: 100%;
  background-color: var(--primary-active);
  border-radius: 30px;
  /* padding: 0.62rem; */
  padding: 0 10px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--website_background-background);
  text-align: right;
  transition: opacity 0.3s ease-in;
}

.footer__link {
  color: #FFFFFF;

  line-height: 0.75rem;
  margin-top: 0.75rem;
  font-size: var(--caption-size);
  font-weight: var(--caption-weight);
}

.footer__text-privecy {
  color: #FFFFFF;

  line-height: 0.75rem;
  margin-top: 0.75rem;
}

.footer__text-privecy_link {
  text-decoration: underline;
}

.footer__email-block_inactive {
  opacity: 0.3;
  pointer-events: none;
}

.footer__polza {
  width: 102px;
  height: 50px;
}

.footer__polza-fill {
  fill: #FFFFFF;
}

.footer__socials {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
}

.footer__links{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
}

.footer__social {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

}

.footer__social-icon {
  width: 32px;
  height: 32px;
}

.footer__social-icon-fill {
  fill: #262424;
}

.footer__social-icon-bg-fill {
  fill: #FFFFFF;
}

.footer__social-title{
  color: #FFFFFF;

  line-height: 0.75rem;
  font-size: var(--paragrah-size);
  font-weight: var(--paragrah-weight);
}

@media (max-width: 1100px) {
  .footer__content {
    grid-template-columns: 0.35fr 0.15fr 0.4fr;
  }
}

@media (max-width: 1000px) {
  .footer {
    padding: 2rem 1rem;
  }
}

@media (max-width: 930px) {
  .footer__email-block {
    /* height: 1.5rem; */
  }
}

@media (max-width: 710px) {
  .footer__content {
    grid-template-columns: 0.35fr 0.15fr 0.4fr;
  }
}

@media (max-width: 500px) {
  .footer__content {
    grid-template-columns: 1fr;
    column-gap: 24px;
  }

  .footer__email-block {
    margin-top: 0;
  }
  .footer__socials {
justify-content: center;
  }
}

@media (max-width: 800px) {
  .footer__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
}